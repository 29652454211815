import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.no';

export default (el, { cleave: cleaveOpts = null }) => {

    let cleave;

    const init = () => {
        if (cleaveOpts) {
            cleave = new Cleave(el, cleaveOpts);
        }
    };

    const destroy = () => {
        if (cleave) {
            cleave.destroy();
        }
    };

    return {
        init,
        destroy
    };

};
