import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

export default el => {
    const $el = $(el);
    const $results = $el.find('[data-result]');
    const $marker = $el.find('[data-underline-bar]');

    let isMarkerVisible = false;

    const switchResult = $trigger => {
        const href = $trigger.attr('href');
        const $targetResult = $el.find(href);

        if ($targetResult.length > 0) {
            $results.css({ display: 'none' });
            $targetResult.css({ display: '' });
            //window.location.hash = '';

            if (isMarkerVisible) {
                gsap.to($marker.nodes, { duration: 0.6, left: $trigger.position().left + $trigger.width() / 2 - $marker.width() / 2, ease: 'quint.out' });
            } else {
                $marker.css({ left: $trigger.position().left + $trigger.width() / 2 - $marker.width() / 2 });
                gsap.set($marker.nodes, { scaleX: 0 });
                gsap.to($marker.nodes, { duration: 0.6, scaleX: 1, opacity: 1, ease: 'quint.out' });
                isMarkerVisible = true;
            }
        }
    };

    const init = () => {
        if ($results.length > 1) {
            $marker.css({ display: 'block', opacity: 0 });

            $el.on('click', '[data-result-toggle]', e => {
                e.preventDefault();
                const $trigger = $(e.triggerTarget);
                switchResult($trigger);
            });

            if (window.location.hash !== '') {
                switchResult($($el.find(`[data-result-toggle][href="${window.location.hash}"]`).nodes[0]));
            } else {
                switchResult($($el.find('[data-result-toggle]').nodes[0]));
            }
        }
    };

    const destroy = () => {
        if ($results.length > 1) {
            $el.off('click');
        }
    };

    return {
        init,
        destroy
    };

};
