import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Config from '@vaersaagod/tools/Config';
import request from '@vaersaagod/tools/request';
import serialize from 'form-serialize';

import { SHOW_FLASH_MESSAGE, FAVOURITES_UPDATED } from '../lib/events';

export default el => {
    const $el = $(el);
    const $spinner = $el.find('[data-spinner]');
    const $buttonContent = $el.find('[data-button-content]');

    const purchasableId = $el.find('input[name="purchasableId"]').val();

    let isRequesting = false;
    let isSelected = false;

    const submit = () => {
        if (!isRequesting) {
            const fields = serialize($el.get(0), { hash: false });
            isRequesting = true;

            $buttonContent.css({ opacity: 0 });
            $spinner.css({ display: 'block' });

            request
                .post(document.location.href)
                .accept('application/json')
                .send(fields)
                .then(res => {
                    const { status, body } = res || {};

                    if (status === 200 && body) {
                        if (body.success === true) {
                            addedToCartSuccess(body);
                        } else {
                            // console.error('her', body);
                            showErrorMessages(body);
                        }
                    } else {
                        // console.error('der');
                        showGenericError(null);
                    }
                })
                .catch(error => {
                    // console.error('ger');
                    showGenericError(null);
                })
                .then(() => {
                    isRequesting = false;
                    $buttonContent.css({ opacity: '' });
                    $spinner.css({ display: 'none' });
                });
        }
    };

    const addedToCartSuccess = result => {
        Dispatch.emit(SHOW_FLASH_MESSAGE, {
            message: result.message
        });
        
        Dispatch.emit(FAVOURITES_UPDATED, {
            count: result.favouriteCount
        });
        
        setSelected(!isSelected);
    };

    const showErrorMessages = response => {
        let errors = [];

        if (response.error !== undefined && response.error !== '') {
            errors.push(response.error);
        }

        if (response.errors !== undefined) {
            Object.keys(response.errors).forEach(val => {
                const err = response.errors[val];
                errors = [...errors, ...err];
            });
        }

        Dispatch.emit(SHOW_FLASH_MESSAGE, {
            message: errors.join('<br>'),
            isError: true
        });
    };

    const showGenericError = errorMessage => {
        Dispatch.emit(SHOW_FLASH_MESSAGE, {
            message: errorMessage || 'En feil oppstod!',
            isError: true
        });
    };

    const setSelected = selected => {
        isSelected = selected;
        
        if (selected) {
            $el.find('.icon-w-bg').addClass('is-selected');
        } else {
            $el.find('.icon-w-bg').removeClass('is-selected');
        }
    };

    const init = () => {
        $el.on('submit', e => {
            e.preventDefault();

            submit();
        });

        if (Config.get('favouriteIds').includes(parseInt(purchasableId, 10))) {
            setSelected(true);
        }
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
