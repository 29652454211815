import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';

export default (el, props) => {
    const HIDE_THRESHOLD = 1000;
    const COLLAPSE_THRESHOLD = 10;

    const $el = $(el);
    const $button = $el.find('[data-button]');
    const $buttonText = $el.find('[data-button-text]');

    const targetWidth = parseInt($button.height());

    let isCollapsed = false;
    let isVisible = true;
    let currentScrollTop = 0;

    const init = () => {
        currentScrollTop = Viewport.scrollTop;
        $el.css({ opacity: 1 });
        
        checkScroll(true);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        Viewport.off('scroll', onScroll);
    };

    const onScroll = () => {
        if (Math.abs(Viewport.scrollTop - currentScrollTop) < 5) {
            return;
        }
        
        checkScroll(false);
    };

    const checkScroll = immediate => {
        const { scrollTop } = Viewport;
        const direction = scrollTop > currentScrollTop ? 'down' : 'up';
        
        if (!isCollapsed && Viewport.scrollTop > COLLAPSE_THRESHOLD) {
            collapse(immediate);
        } else if (isCollapsed && Viewport.scrollTop < COLLAPSE_THRESHOLD) {
            expand(immediate);
        }

        if (isVisible && Viewport.scrollTop > HIDE_THRESHOLD && direction === 'down') {
            hide(immediate);
        } else if (!isVisible && (Viewport.scrollTop < HIDE_THRESHOLD || direction === 'up')) {
            show(immediate);
        }

        currentScrollTop = scrollTop;
    };

    const collapse = immediate => {
        gsap.to($buttonText.get(0), { opacity: 0, duration: !immediate ? 0.1 : 0, ease: 'sine.out', onComplete: () => {
            gsap.to($buttonText.get(0), { width: 0, duration: !immediate ? 0.2 : 0, ease: 'sine.out' });
            gsap.to($button.get(0), { width: targetWidth, duration: !immediate ? 0.4 : 0, ease: 'quint.out' });
        } });

        isCollapsed = true;
    };

    const expand = immediate => {
        $buttonText.css({ display: 'block', width: 0 });
        gsap.to($buttonText.get(0), { width: '', duration: !immediate ? 0.6 : 0, ease: 'sine.inOut' });
        
        gsap.to($button.get(0), { width: '100%', duration: !immediate ? 0.4 : 0, ease: 'quint.out', onComplete: () => {
            gsap.to($buttonText.get(0), { opacity: 1, duration: !immediate ? 0.1 : 0, ease: 'sine.out' });
        } });

        isCollapsed = false;
    };

    const show = immediate => {
        gsap.to($el.get(0), { y: 0, duration: !immediate ? 0.4 : 0, ease: 'sine.out' });
        isVisible = true;
    };

    const hide = immediate => {
        gsap.to($el.get(0), { y: 88, duration: !immediate ? 0.4 : 0, ease: 'sine.out' });
        isVisible = false;
    };

    return {
        init,
        destroy
    };
};
