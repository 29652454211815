import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import request from '@vaersaagod/tools/request';

import gsap from 'gsap';

export default (el, props) => {
    const BP_IS_FIXED = 980;

    const $el = $(el);
    const $formPanel = $el.find('[data-form-panel]');
    const $statusPanel = $el.find('[data-status-panel]');
    const $statusPanelText = $el.find('[data-status-panel-text]');
    const $statusPanelExpires = $el.find('[data-status-panel-expires]');
    const $helpPanel = $el.find('[data-help-panel]');
    const $postalCodeElement = $el.find('[data-postalcode-current]');
    const $postalCodeInput = $el.find('[data-postalcode-input]');
    const $loadingOverlay = $el.find('[data-loading-overlay]');
    const $errorMessage = $el.find('[data-error-message]');

    const { canFixed, lookupEndpoint } = props;

    const dayLookup = [
        'søndag',
        'mandag',
        'tirsdag',
        'onsdag',
        'torsdag',
        'fredag',
        'lørdag'
    ];

    let { postalCode } = props;
    let clientServerTimeDiff = 0;
    let currentExpiresAt = null;
    let isRequesting = false;
    let updateInterval = -1;

    const updateExpiresText = () => {
        const now = new Date();
        const timeDiff = currentExpiresAt - now + clientServerTimeDiff;
        const seconds = Math.floor((timeDiff / 1000) % 60);
        const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
        const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
        
        let timeString = '';
        
        if (hours > 0) {
            timeString = `Bestill innen ${hours} timer og ${minutes} minutter`;
        } else if (minutes > 0) {
            timeString = `Bestill innen ${minutes} minutter`;
        } else if (seconds > 0) {
            timeString = `Bestill innen ${seconds} sekunder`;
        } 
        
        $statusPanelExpires.text(timeString);
    };

    const parseResult = data => {
        const clientNow = new Date();
        const serverNow = new Date(data.now);
        clientServerTimeDiff = serverNow - clientNow;
        const tomorrow = new Date(serverNow.getFullYear(), serverNow.getMonth(), serverNow.getDate() + 1);

        const results = data.result;

        let statusText = '';
        let expiresText = '';

        if (results.length > 0) {
            const earliest = results[0];
            const expiresAt = new Date(earliest.expiresAt);

            currentExpiresAt = expiresAt;

            const isSameDay = expiresAt.getDate() === serverNow.getDate();
            const isTomorrow = expiresAt.getDate() === tomorrow.getDate();

            let deliveryDayName = dayLookup[expiresAt.getDay()];

            if (isSameDay) {
                deliveryDayName = 'i dag';
            }
            if (isTomorrow) {
                deliveryDayName = 'i morgen';
            }

            statusText = `Hjem ${deliveryDayName} fra kr 69,-`;

            if (isSameDay) {
                clearInterval(updateInterval);
                
                updateExpiresText();

                updateInterval = setInterval(() => {
                    updateExpiresText();
                }, 5000);
            } else {
                expiresText = `Bestill innen ${deliveryDayName} kl 13:00`;
                $statusPanelExpires.text(expiresText);
            }
        } else {
            statusText = 'Beklager!';
            expiresText = 'Porterbuddy kan ikke levere til ditt postnummer.';
            $statusPanelExpires.text(expiresText);
        }

        $statusPanelText.text(statusText);
        $statusPanel.removeClass('hidden');
    };

    const showError = () => {
        $errorMessage.removeClass('hidden');
    };

    const storePostalCode = code => {
        const storage = window.localStorage;

        if (storage) {
            storage.setItem('porterbuddyWidgetPostalCode', code);
        }
    };

    const getStoredPostalCode = () => {
        const storage = window.localStorage;

        if (storage) {
            return storage.getItem('porterbuddyWidgetPostalCode');
        }

        return null;
    };

    const checkInput = () => {
        const val = $postalCodeInput.val();

        if (val.length === 4) {
            postalCode = val;
            storePostalCode(postalCode);
            $postalCodeElement.text(val);
            $loadingOverlay.removeClass('hidden');
            $formPanel.addClass('hidden');

            if (!isRequesting) {
                isRequesting = true;

                let url = lookupEndpoint;

                if (url.indexOf('?') > -1) {
                    url += `&postalCode=${postalCode}`;
                } else {
                    url += `?postalCode=${postalCode}`;
                }

                request
                    .get(url)
                    .accept('application/json')
                    .then(res => {
                        const { status, body } = res || {};

                        if (status === 200 && body) {
                            if (body.success === true) {
                                parseResult(body);
                            } else {
                                showError();
                            }
                        } else {
                            showError();
                        }
                    })
                    .catch(error => {
                        showError();
                    })
                    .then(() => {
                        isRequesting = false;
                        $loadingOverlay.addClass('hidden');
                    });
            }
        }
    };

    const close = () => {
        if (canFixed && Viewport.breakpoint.size >= BP_IS_FIXED) {
            gsap.to($el.nodes, {
                bottom: -$el.height(),
                duration: 0.6,
                ease: 'quint.out',
                onComplete: () => {
                    $el.remove();
                }
            });
        } else {
            gsap.to($el.nodes, {
                height: 0,
                duration: 0.6,
                ease: 'quint.out',
                onComplete: () => {
                    $el.remove();
                }
            });
        }
    };

    const init = () => {
        $el.on('click', '[data-btn-close]', e => {
            e.preventDefault();
            close();
        });

        $el.on('click', '[data-form-panel-trigger]', e => {
            e.preventDefault();
            $statusPanel.addClass('hidden');
            $errorMessage.addClass('hidden');
            $formPanel.removeClass('hidden');
        });

        $el.on('click', '[data-help-close]', e => {
            e.preventDefault();
            $helpPanel.addClass('hidden');
        });

        $el.on('click', '[data-btn-help]', e => {
            e.preventDefault();
            $helpPanel.removeClass('hidden');
        });

        $formPanel.on('submit', 'form', e => {
            e.preventDefault();
            checkInput();
        });

        if (postalCode === null) {
            postalCode = getStoredPostalCode();
        }

        if (postalCode !== null) {
            $postalCodeElement.text(postalCode);
            $postalCodeInput.val(postalCode);
            checkInput();
        } else {
            $formPanel.removeClass('hidden');
            $loadingOverlay.addClass('hidden');
        }

        $el.removeClass('hidden');

        if (canFixed && Viewport.breakpoint.size >= BP_IS_FIXED) {
            $el.css({ bottom: -$el.height() });

            gsap.to($el.nodes, {
                bottom: 0,
                duration: 0.6,
                delay: 2,
                ease: 'quint.out'
            });
        }
    };

    const destroy = () => {
        if ($el) {
            $el.off('click');
            $formPanel.off('submit');
        }
        
        clearInterval(updateInterval);
    };

    return {
        init,
        destroy
    };
};
