import './lib/polyfills';
import Viewport from '@vaersaagod/tools/Viewport';
import Components from '@vaersaagod/tools/Components';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import AddToCartHandler from './lib/AddToCartHandler';

import ViewportHeight from './lib/ViewportHeight';
import lazySizes from './lib/lazysizes';
import BlurHashed from './lib/BlurHashed';

gsap.defaults({ overwrite: 'auto' });
gsap.registerPlugin(ScrollToPlugin);

const init = () => {
    Viewport.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    Components.init();
    AddToCartHandler.init();
    BlurHashed.init();
    lazySizes.init();
};

require('doc-ready')(init);
