import $ from '@vaersaagod/tools/Dom';
import Components from '@vaersaagod/tools/Components';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import superagent from '@vaersaagod/tools/request';
import serialize from 'form-serialize';

import { SHOW_FLASH_MESSAGE, CART_UPDATED } from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $loadingOverlay = $el.find('[data-loading-overlay]');

    const { reloadUrl } = props;
    let isSubmitting = false;

    const injectNewMarkup = html => {
        const $html = $(`<div>${html}</div>`);
        const $ajaxWrap = $html.find('[data-ajax-wrapper]');
        const $newData = $html.find('[data-component="CartForm"]').eq(0);

        const opts = $ajaxWrap.data('ajax-wrapper');

        if (opts.flashMessage !== undefined && opts.flashMessage !== '' && opts.isError) {
            Dispatch.emit(SHOW_FLASH_MESSAGE, { message: opts.flashMessage, isError: opts.isError });
        }

        Components.destroy(el.children);
        $el.html($newData.html());
        Components.init(el);
    };

    const loadData = () => {
        superagent
            .get(`/${reloadUrl}`)
            .then(res => {
                if (!res) {
                    return;
                }
                const { status, text: html } = res;
                if (status !== 200) {
                    throw new Error();
                }

                injectNewMarkup(html);
            })
            .catch(error => {
                console.error({ error });
                Dispatch.emit(SHOW_FLASH_MESSAGE, { message: 'En feil oppstod, vennligst prøv på nytt!', isError: true });
            })
            .then(() => {
                isSubmitting = false;
            });
    };

    const submit = () => {
        if (isSubmitting) {
            return;
        }

        isSubmitting = true;
        $el.addClass('is-submitting');

        const data = serialize($el.get(0), { hash: false });

        // POST the request
        superagent
            .post(window.location.href)
            .accept('application/json')
            .send(data)
            .then(res => {
                const { status, body } = res || {};

                if (status === 200 && body) {
                    if (body.success === true) {
                        loadData();
                        
                        Dispatch.emit(CART_UPDATED, {
                            cart: body.cart
                        });
                    } else {
                        Dispatch.emit(SHOW_FLASH_MESSAGE, { message: 'En feil oppstod, vennligst prøv på nytt!', isError: true });
                    }
                } else {
                    Dispatch.emit(SHOW_FLASH_MESSAGE, { message: 'En feil oppstod, vennligst prøv på nytt!', isError: true });
                }

                return null;
            })
            .catch(error => {
                console.error({ error });
                Dispatch.emit(SHOW_FLASH_MESSAGE, { message: 'En feil oppstod, vennligst prøv på nytt!', isError: true });
            })
            .then(() => {
                isSubmitting = false;
                $el.removeClass('is-submitting');
            });
    };

    const init = () => {
        $el.on('click', '[data-remove-lineitem]', e => {
            const $button = $(e.triggerTarget);
            const targetId = $button.data('remove-lineitem');
            const $input = $el.find(`#${targetId}`);

            if ($input.length > 0) {
                $input.val('1');
                submit();
            }
        });

        $el.on('change', '[data-qty-select]', e => {
            submit();
        });

        $el.on('submit', e => {
            e.preventDefault();
            submit();
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
