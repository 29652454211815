import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

import gsap from 'gsap';

export default el => {

    const $el = $(el);
    const toggleBtn = $el.find('[data-toggle]').get(0);
    const menu = toggleBtn.nextElementSibling;
    const items = $(menu).find('[data-item]').get();

    let isExpanded = false;
    let activeElementBeforeExpand;

    const positionMenu = () => {
        gsap.set(menu, { clearProps: 'x' });
        const viewportWidth = $('#main').get(0).getBoundingClientRect().width - 32;
        const { width: menuWidth, left } = menu.getBoundingClientRect();
        let overflowX = (left + menuWidth) - (viewportWidth + 16);
        if (overflowX < 0) {
            overflowX = 0;
        }
        gsap.set(menu, { x: -overflowX });
    };

    const collapse = () => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        toggleBtn.setAttribute('aria-expanded', 'false');
        gsap.killTweensOf(menu);
        gsap.timeline({
            onComplete() {
                gsap.set(menu, { clearProps: 'opacity,x,y' });
                menu.hidden = true;
            }
        })
            .to(menu, { opacity: 0, duration: 0.3 }, 0);
        if (activeElementBeforeExpand) {
            try {
                activeElementBeforeExpand.focus();
            } catch (error) {};
        }
        activeElementBeforeExpand = null;
    };

    const expand = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        activeElementBeforeExpand = document.activeElement || null;
        toggleBtn.setAttribute('aria-expanded', 'true');
        menu.hidden = false;
        gsap.killTweensOf(menu);
        positionMenu();
        // Focus to first item
        const firstItem = $(menu).find('a,button').get(0);
        if (firstItem) {
            firstItem.focus();
        }
        gsap.timeline()
            .fromTo(menu, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
            .fromTo(menu, { y: -8 }, { y: 0, duration: 0.3 }, 0)
            .set(menu, { clearProps: 'opacity' });
    };

    const toggle = () => {
        if (!isExpanded) {
            expand();
        } else {
            collapse();
        }
    };

    const onBodyKeyUp = e => {
        if (!isExpanded) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            collapse();
        }
    };

    const onBodyClick = e => {
        if (!isExpanded) return;
        const { target } = e;
        if (target !== el && !el.contains(target)) {
            if (activeElementBeforeExpand && (document.activeElement || null) !== activeElementBeforeExpand) {
                // Avoid resetting the focus if the user clicked something else on the page
                activeElementBeforeExpand = null;
            }
            collapse();
        }
    };

    const onToggleBtnKeyDown = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['ArrowDown', 40].indexOf(key) > -1) {
            const item = $(items).get(0);
            if (!item) {
                return;
            }
            e.preventDefault();
            expand();
            item.focus();
        }
    };

    const onItemKeyDown = e => {

        const key = e.key || e.keyCode || e.which || null;
        const focusNext = ['ArrowDown', 40].indexOf(key) > -1 || ['ArrowRight', 39].indexOf(key) > -1;
        const focusPrev = ['ArrowUp', 38].indexOf(key) > -1 || ['ArrowLeft', 37].indexOf(key) > -1;

        if (!focusNext && !focusPrev) {
            return;
        }

        e.preventDefault();

        const $items = $(items);
        const index = $items.index(e.target);
        let item;

        if (focusNext) {
            item = $items.get(index + 1) || $items.get(0);
        } else if (focusPrev) {
            item = $items.get(index - 1) || $items.get($items.length - 1);
        }

        item.focus();
    };

    const onResize = () => {
        if (!isExpanded) {
            return;
        }
        positionMenu();
    };

    const onScroll = () => {
        if (!isExpanded) {
            return;
        }
        const { top, height } = menu.getBoundingClientRect();
        if (top + height < -100) {
            collapse();
        }
    };

    const init = () => {
        $(toggleBtn)
            .on('click', toggle)
            .on('keydown', onToggleBtnKeyDown);
        $(items)
            .on('keydown', onItemKeyDown);
        $('body')
            .on('click focusin', onBodyClick)
            .on('keyup', onBodyKeyUp);
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        $(toggleBtn).off('click keydown');
        $(items).off('keydown');
        $('body')
            .off('click focusin', onBodyClick)
            .off('keyup', onBodyKeyUp);
        Viewport.off('resize', onResize);
        Viewport.on('scroll', onScroll);
    };

    return {
        init,
        destroy
    };

};
