import $ from '@vaersaagod/tools/Dom';
import Components from '@vaersaagod/tools/Components';
import request from '@vaersaagod/tools/request';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $input = $el.find('input[name="q"]');
    const $result = $el.find('[data-result-content]');

    const { baseUrl } = props;

    const parseData = data => {
        const $data = $(data);
        $result.empty().append($data.find('[data-result-content]'));
        //$spinner.addClass('hidden');
        Components.init($result);
        
        //gsap.to($content.nodes, { duration: 0.2, opacity: 1 });
    };
    
    const doSearch = () => {
        request
            .get(`${baseUrl}`)
            .query({ q: $input.val() })
            .then(res => {
                if (res.statusCode === 200) {
                    parseData(res.text);
                } else {
                    // todo : feilmelding
                }
            });
    };

    const init = () => {
        $el.on('submit', e => {
            e.preventDefault();
            
            doSearch();
        });
        
        $el.on('click', '[data-quick-search-term]', e => {
            e.preventDefault();
            const $trigger = $(e.triggerTarget);
            const term = $trigger.data('quick-search-term');
            $input.val(term);
            doSearch();
        });
    };

    const destroy = () => {
        $el.off('submit');
    };

    return {
        init,
        destroy
    };
};
