import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

export default el => {
    const $el = $(el);
    const $baseImage = $el.find('[data-image-base]');
    const $hoverImage = $el.find('[data-image-hover]');

    const showHover = () => {
        // $el.find('[data-btns]').css({ opacity: 1 });
        
        if ($hoverImage.length) {
            gsap.to($baseImage.nodes, { duration: 0.1, opacity: 0 });
            gsap.to($hoverImage.nodes, { duration: 0.1, opacity: 1 });
        } 
    };

    const hideHover = () => {
        // $el.find('[data-btns]').css({ opacity: 0 });

        if ($hoverImage.length) {
            gsap.to($baseImage.nodes, { duration: 0.1, opacity: 1 });
            gsap.to($hoverImage.nodes, { duration: 0.1, opacity: 0 });
        } 
    };

    const init = () => {
        if ($hoverImage.length) {
            $hoverImage.css({ display: 'block', opacity: 0 });
        }
        
        $el.on('mouseenter', showHover).on('mouseleave', hideHover);
        // $el.find('a,button').on('focusin', showHover).on('focusout', hideHover);
    };

    const destroy = () => {
        $el.off('mouseenter mouseleave');
        // $el.find('a,button').off('focusin focusout');
    };

    return {
        init,
        destroy
    };

};
