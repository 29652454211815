import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import { loadFlickity } from '../lib/async-bundles';

require('intersection-observer');

export default el => {
    const $el = $(el);
    const $slides = $el.find('[data-slide]');
    const enabledThreshold = 750;

    let isEnabled = false;

    let observer;

    let Flickity;
    let flkty;
    let currentIndex;

    const initFlickity = () => {
        if (isEnabled) {
            loadFlickity(flickity => {
                Flickity = flickity;

                $slides.css({ display: 'flex' });

                flkty = new Flickity(el, {
                    pageDots: false,
                    adaptiveHeight: false,
                    prevNextButtons: false,
                    setGallerySize: true,
                    groupCells: false,
                    freeScroll: true,
                    contain: true,
                    cellAlign: 'left',
                    resize: true,
                    wrapAround: false,
                    percentPosition: true,
                    accessibility: true,
                    dragThreshold: 10,
                    lazyLoad: false,
                    on: {
                        dragStart: () => {
                            $el.find('a,button').css({ pointerEvents: 'none' });
                        },
                        dragEnd: () => {
                            $el.find('a,button').css({ pointerEvents: '' });
                        }
                    }
                });
            });
        }
    };

    const destroyObserver = () => {
        if (observer !== null) {
            observer.disconnect();
            observer = null;
        }
    };

    const createObserver = () => {
        destroyObserver();

        observer = new IntersectionObserver(entries => {
            const entry = entries[0];
            const { isIntersecting } = entry;
            if (isIntersecting) {
                observer.disconnect();
                observer = null;

                initFlickity();
            }
        }, {
            rootMargin: '100% 0% 100% 0%'
        });

        observer.observe(el);
    };

    const onBreakpoint = e => {
        const oldSize = e.detail.old.size;
        const currentSize = e.detail.current.size;

        isEnabled = currentSize < enabledThreshold;

        // We maybe had slideshow, but not anymore
        if (oldSize < enabledThreshold && currentSize >= enabledThreshold) {
            if (flkty) {
                flkty.destroy();
                $slides.css({ display: '' });
            }
            if (observer) {
                destroyObserver();
            }
        }

        // We didn't have slideshow, but maybe we should
        if (oldSize >= enabledThreshold && currentSize < enabledThreshold) {
            createObserver();
        }
    };

    const init = () => {
        isEnabled = Viewport.breakpoint.size < enabledThreshold;

        observer = new IntersectionObserver(entries => {
            const entry = entries[0];
            const { isIntersecting } = entry;
            if (isIntersecting) {
                observer.disconnect();
                observer = null;

                initFlickity();
            }
        });

        if (isEnabled) {
            createObserver();
        }

        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        destroyObserver();
        if (flkty) {
            flkty.destroy();
        }

        Viewport.off('breakpoint', onBreakpoint);
    };

    return {
        init,
        destroy
    };
};
