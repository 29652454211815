import $ from '@vaersaagod/tools/Dom';
import { decode, isBlurhashValid } from 'blurhash';

const init = () => {
    $('[data-blurhashed]').each(el => {
        const $el = $(el);
        const encodedStr = $el.data('blurhashed');
        
        if (isBlurhashValid(encodedStr)) {
            const ratio = $el.width() / $el.height();
            
            if (!isNaN(ratio)) {
                const width = 30;
                const height = Math.round(30 / ratio);
                
                const pixels = decode(encodedStr, width, height);
         
                const canvas = document.createElement('canvas');
                const $canvas = $(canvas);
                $canvas.attr('width', width).attr('height', height).addClass('blurhash');
                
                const ctx = canvas.getContext('2d');
                const imageData = ctx.createImageData(width, height);
                imageData.data.set(pixels);
                ctx.putImageData(imageData, 0, 0);
                $el.append($canvas);
            }
        } else {
            console.log('Incorrect blurhash encountered');
        }
    });
};

const destroy = () => {

};

// Public api for module
export default {
    init,
    destroy
};
