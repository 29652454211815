import $ from '@vaersaagod/tools/Dom';
import serialize from 'form-serialize';
import superagent from '@vaersaagod/tools/request';
import Dispatch from '@vaersaagod/tools/Dispatch';
import i18n from '../lib/i18n';
import { SHOW_FLASH_MESSAGE } from '../lib/events';

export default form => {

    const $form = $(form);
    const errorDiv = $form.find('[data-error]').get(0);

    let request;

    const hideError = () => {
        if (!errorDiv) {
            return;
        }
        errorDiv.hidden = true;
    };

    const showError = message => {
        if (!errorDiv) {
            Dispatch.emit(SHOW_FLASH_MESSAGE, { message, isError: true });
            return;
        }
        $(errorDiv).text(message);
        errorDiv.hidden = false;
    };

    const onSubmit = e => {
        e.preventDefault();

        if (request) {
            return;
        }

        hideError();

        $form.addClass('is-submitting');

        const data = serialize($form.get(0), { hash: false, empty: true });

        request = superagent
            .post(window.location.href)
            .accept('application/json')
            .send(data);

        request
            .then(({ status, body }) => {
                if (status !== 200 || !body) {
                    throw new Error();
                }
                if (body.success) {
                    let { returnUrl = window.location.href } = body;
                    if (returnUrl.slice(0, 4) !== 'http') {
                        returnUrl = `/${returnUrl}`;
                    }
                    window.location.href = returnUrl;
                    return true;
                }
                const { error } = body;
                showError(error || i18n('error.generic'));
                return false;
            })
            .catch(error => {
                console.log('her', error);
                showError(i18n('error.generic'));
            })
            .then(success => {
                if (!success) {
                    request = null;
                    $form.removeClass('is-submitting');
                }
            });
    };

    const init = () => {
        $form.on('submit', onSubmit);
    };

    const destroy = () => {
        $form.off('submit', onSubmit);
    };

    return {
        init,
        destroy
    };

};
