import $ from '@vaersaagod/tools/Dom';
import superagent from '@vaersaagod/tools/request';
import i18n from '../../lib/i18n';

export default (el, { shop, appType, date, endpoint }) => {

    const toggle = $(el).find('button[aria-expanded]').get(0);
    const timeslotsPanel = toggle.nextElementSibling;
    const timeslotsContainer = $(el).find('[data-timeslots]').get(0);
    const spinner = $(el).find('[data-timeslots-spinner]').get(0);
    const errorDiv = $(el).find('[data-timeslots-error]').get(0);

    let isExpanded = false;
    let isFetchingTimeslots = false;
    let hasFetchedTimeslots = false;

    const fetchTimeslots = () => {
        if (isFetchingTimeslots || hasFetchedTimeslots) return;
        isFetchingTimeslots = true;
        spinner.hidden = false;
        errorDiv.hidden = true;
        superagent
            .get(endpoint)
            .query({
                shop,
                appType,
                date
            })
            .then(res => {
                const { status, body } = res;
                if (status !== 200 || !body.success || !body.html) {
                    $(errorDiv).text(body.message || i18n('error.generic'));
                    throw new Error();
                }
                hasFetchedTimeslots = true;
                $(timeslotsContainer).html(body.html);
                const button = $(timeslotsContainer).find('button').get(0);
                if (button) button.focus();
            })
            .catch(error => {
                console.error(error);
                errorDiv.hidden = false;
            })
            .then(() => {
                isFetchingTimeslots = false;
                spinner.hidden = true;
            });
    };

    const collapse = () => {
        if (!isExpanded) return;
        isExpanded = false;
        toggle.setAttribute('aria-expanded', 'false');
        timeslotsPanel.hidden = true;
    };

    const expand = () => {
        if (isExpanded) return;
        isExpanded = true;
        toggle.setAttribute('aria-expanded', 'true');
        timeslotsPanel.hidden = false;
        fetchTimeslots();
    };

    const onToggleClick = () => {
        if (isExpanded) {
            collapse();
        } else {
            expand();
        }
    };

    const onSubmit = () => {
        collapse();
    };

    const init = () => {
        $(toggle).on('click', onToggleClick);
        $(el).on('submit', onSubmit);
    };

    const destroy = () => {
        $(toggle).off('click');
        $(el).off('submit');
    };

    return {
        init,
        destroy
    };

};
