import $ from '@vaersaagod/tools/Dom';
import request from '@vaersaagod/tools/request';
import serialize from 'form-serialize';
import gsap from 'gsap';

let isRequesting = false;

export default (el, props) => {
    const $el = $(el);
    const $form = $el.find('form');
    const $spinner = $el.find('[data-spinner]');
    const $buttonContent = $el.find('[data-button-content]');
    const $formWrap = $el.find('[data-form-wrap]');
    const $formSuccess = $el.find('[data-form-success]');

    let hasStarted = false;
    
    const submit = () => {
        if (!isRequesting) {
            const fields = serialize($form.get(0), { hash: false });
            isRequesting = true;

            $buttonContent.css({ opacity: 0 });
            $spinner.css({ display: 'block' });
            clearErrors();

            request
                .post(document.location.href)
                .accept('application/json')
                .send(fields)
                .then(res => {
                    const { status, body } = res || {};

                    if (status === 200 && body) {
                        if (body.success === true) {
                            registerSuccess(body);
                        } else {
                            // console.error('her', body);
                            showErrorMessages(body);
                        }
                    } else {
                        // console.error('der');
                        showGenericError(null);
                    }
                })
                .catch(error => {
                    // console.error('ger');
                    showGenericError(null);
                })
                .then(() => {
                    isRequesting = false;
                    $buttonContent.css({ opacity: '' });
                    $spinner.css({ display: 'none' });
                });
        }
    };
    
    const clearErrors = () => {
        $form.find('[data-errors]').attr('hidden', '').empty();
        $form.find('[data-form-error-container]').empty();
    };
    
    const registerSuccess = result => {
        // console.log(result);
        $form.find('input[type="text"], input[type="email"]').val('');
        
        $formSuccess.css({ display: 'block' });
        const targetHeight = $formSuccess.height();
        $formSuccess.css({ display: 'none' });
        
        gsap.to($formWrap.nodes, { 
            opacity: 0, 
            duration: 0.05,
            onComplete: () => {
                gsap.to($formWrap.nodes, {
                    height: targetHeight, 
                    duration: 0.4, 
                    ease: 'quart.inOut',
                    onComplete: () => {
                        $formWrap.css({ display: 'none' });
                        $formSuccess.css({ display: 'block', opacity: 0 });
                        gsap.to($formSuccess.nodes, { opacity: 1, duration: 0.4 });
                        gsap.to(window, { duration: 0.6, scrollTo: { y: $el.offset().top - 150, autoKill: false }, ease: 'cubic.inOut' });
                    }
                });
            } 
        });
        
        track('completed');
    };

    const showErrorMessages = response => {
        Object.keys(response.errors).forEach(val => {
            const $errorContainer = $form.find(`[data-errors="${val}"]`);
            const errors = response.errors[val];
            
            if ($errorContainer.length && errors && errors.length) {
                if (val === 'acceptTerms') {
                    $errorContainer.html('Du må akseptere medlemsvilkårene');
                } else if (val === 'acceptEmailContact') {
                    $errorContainer.html('Du må akseptere at Krogh Optikk sender deg informasjon på e-post');
                } else if (val === 'acceptSmsContact') {
                    $errorContainer.html('Du må akseptere at Krogh Optikk sender deg informasjon på SMS');
                } else {
                    $errorContainer.html(errors.join('<br>'));
                }
                
                $errorContainer.attr('hidden', null);
            }
        });
        
        if (response.errors['*'] !== undefined) {
            const $generalErrorContainer = $form.find('[data-form-error-container]');
            const errors = response.errors['*'];
            
            if ($generalErrorContainer.length && errors && errors.length) {
                $generalErrorContainer.html(`<div class="mb-40 errors">${errors.join('<br>')}</div>`);
            }
        }
    };

    const showGenericError = errorMessage => {
        // console.log(errorMessage);
        const $generalErrorContainer = $form.find('[data-form-error-container]');
        
        if ($generalErrorContainer.length) {
            $generalErrorContainer.html(`<div class="mb-40 errors">${errorMessage}</div>`);
        }

        /*
        Dispatch.emit(SHOW_FLASH_MESSAGE, {
            message: errorMessage || 'En feil oppstod!',
            isError: true
        });
         */
    };
    
    const track = event => {
        if (window.dataLayer === undefined) {
            window.dataLayer = [];
        }
        
        if (event === 'started') {
            window.dataLayer.push({
                event: 'Krogh+ form – Started'
            });
        }
        if (event === 'completed') {
            window.dataLayer.push({
                event: 'Krogh+ form – Complete'
            });
        }
    };

    const init = () => {
        $form.on('submit', e => {
            e.preventDefault();
            submit();
        });
        
        $form.on('change', 'input', e => {
            if (!hasStarted) {
                track('started');
                hasStarted = true;
            }
        });
    };

    const destroy = () => {
        $form.off('submit');
        $form.off('change');
    };

    return {
        init,
        destroy
    };
};
