import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Config from '@vaersaagod/tools/Config';
import gsap from 'gsap';

import { CART_UPDATED } from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $countContainer = $el.find('[data-count]');
    const $countContainerInner = $el.find('[data-count-inner]');

    let isVisible = false;

    const onCartUpdated = (key, data) => {
        if (data.cart !== undefined) {
            const count = data.cart.totalQty;
            $countContainerInner.text(count);
            
            if (count > 0) {
                if (!isVisible) {
                    $countContainer.removeClass('hidden').addClass('flex');
                    isVisible = true;
                }
                $el.attr('aria-label', `Du har ${count} produkter i handlekurven. Klikk for å vise handlekurven.`);
            } else if (isVisible) {
                $countContainer.addClass('hidden').removeClass('hidden');
                isVisible = false;
                $el.attr('aria-label', 'Du har ingen produkter i handlekurven. Klikk for å vise handlekurven.');
            }
        }
    };

    const init = () => {
        const count = Config.get('cartCount');
        
        if (count > 0) {
            isVisible = true;
            $countContainerInner.text(count);
            $countContainer.removeClass('hidden').addClass('flex');
            $el.attr('aria-label', `Du har ${count} produkter i handlekurven. Klikk for å vise handlekurven.`);
        } else {
            $el.attr('aria-label', 'Du har ingen produkter i handlekurven. Klikk for å vise handlekurven.');
        }
        
        Dispatch.on(CART_UPDATED, onCartUpdated);
    };

    const destroy = () => {
        Dispatch.off(CART_UPDATED, onCartUpdated);
    };

    return {
        init,
        destroy
    };
};
