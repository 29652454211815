/**
 * Generic message/alert/error handler.
 * 
 * Example: Dispatch.emit(SHOW_FLASH_MESSAGE, { message: 'Det er noe feil!', isError: true });
 */

import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

import { SHOW_FLASH_MESSAGE, HIDE_FLASH_MESSAGE } from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $messageContainer = $el.find('[data-message-container]');
    const $closeBtn = $el.find('[data-close-button]');
    const { errorClass, defaultClass } = props;
    
    let { isError } = props;
    let timeout = -1;
    let isVisible = false;

    const close = callback => {
        gsap.to($el.nodes, {
            duration: 0.3,
            top: $el.height() * -1,
            ease: 'sine.out',
            onComplete: () => {
                if (callback !== undefined) {
                    callback();
                } else {
                    $messageContainer.empty();
                }
                
                $closeBtn.attr('tabindex', '-1');
            }
        });
        
        isVisible = false;
    };

    const show = () => {
        gsap.to($el.nodes, {
            duration: 0.7,
            delay: 0,
            top: 0,
            ease: 'quint.out'
        });
        
        isVisible = true;
        $closeBtn.attr('tabindex', '');

        timeout = setTimeout(() => {
            close();
        }, isError ? 10000 : 3000);
    };

    const reset = () => {
        $el.css({ display: 'flex' });
        $el.css({ top: $el.height() * -1 });
        $closeBtn.attr('tabindex', '-1');
        
        if (isError) {
            $el.addClass(errorClass).removeClass(defaultClass);
        } else {
            $el.removeClass(errorClass).addClass(defaultClass);
        }
        
        clearTimeout(timeout);
    };

    const onShowFlashMessage = (key, data) => {
        if (data.message !== undefined && data.message !== '') {
            isError = data.isError !== undefined ? data.isError : false;
            
            const preamble = isError ? 'Feilmelding' : 'Informasjon';
            const content = `<span class="sr-only">${preamble}: </span>${data.message}`;
            $messageContainer.html(content);
            
            if (isVisible) {
                close(() => {
                    $messageContainer.html(content);
                    reset();
                    show();
                });
            } else {
                reset();
                show();
            }
        }
    };
    
    const onHideFlashMessage = (key, data) => {
        if (isVisible) {
            clearTimeout(timeout);
            close();
        }
    };
    
    const onBreakpointChange = () => {
        reset();
    };

    const init = () => {
        reset();

        $closeBtn.on('click', () => {
            close();
        });

        $el.on('mouseenter', () => {
            clearTimeout(timeout);
        });

        if ($messageContainer.text().length > 0) {
            show();
        }

        Viewport.on('breakpoint', onBreakpointChange);
        Dispatch.on(SHOW_FLASH_MESSAGE, onShowFlashMessage);
        Dispatch.on(HIDE_FLASH_MESSAGE, onHideFlashMessage);
    };

    const destroy = () => {
        $closeBtn.off('click');
        $el.off('mouseenter');
        clearTimeout(timeout);
        Dispatch.off(SHOW_FLASH_MESSAGE, onShowFlashMessage);
        Dispatch.off(HIDE_FLASH_MESSAGE, onHideFlashMessage);
    };

    return {
        init,
        destroy
    };
};
