import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import request from '@vaersaagod/tools/request';
import serialize from 'form-serialize';
import gsap from 'gsap';

import { SHOW_FLASH_MESSAGE, HIDE_SIDE_PANEL, FAVOURITES_UPDATED } from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    
    let isRequesting = false;
    
    const submitDelete = $form => {
        if (!isRequesting) {
            const $item = $($form.get(0).closest('[data-favourites-item]'));
            const $itemInner = $item.find('[data-favourites-item-inner]');
            const $spinner = $item.find('[data-item-spinner]');
            
            $itemInner.css({ opacity: 0.2 });
            $spinner.css({ display: 'block' });
            
            const fields = serialize($form.get(0), { hash: false });
            isRequesting = true;

            request
                .post(document.location.href)
                .accept('application/json')
                .send(fields)
                .then(res => {
                    const { status, body } = res || {};

                    if (status === 200 && body) {
                        if (body.success === true) {
                            deleteSuccess(body);
                            removeItem($item);
                        } else {
                            // console.error('her', body);
                            showErrorMessages(body);
                        }
                    } else {
                        // console.error('der');
                        showGenericError(null);
                    }
                })
                .catch(error => {
                    // console.error('ser');
                    showGenericError(null);
                })
                .then(() => {
                    isRequesting = false;
                    $itemInner.css({ opacity: '' });
                    $spinner.css({ display: 'none' });
                });
        }
    };

    const deleteSuccess = result => {
        Dispatch.emit(SHOW_FLASH_MESSAGE, {
            message: result.message
        });

        Dispatch.emit(FAVOURITES_UPDATED, {
            count: result.favouriteCount
        });
    };

    const showErrorMessages = response => {
        let errors = [];

        if (response.error !== undefined && response.error !== '') {
            errors.push(response.error);
        }

        if (response.errors !== undefined) {
            Object.keys(response.errors).forEach(val => {
                const err = response.errors[val];
                errors = [...errors, ...err];
            });
        }

        Dispatch.emit(SHOW_FLASH_MESSAGE, {
            message: errors.join('<br>'),
            isError: true
        });
    };

    const showGenericError = errorMessage => {
        Dispatch.emit(SHOW_FLASH_MESSAGE, {
            message: errorMessage || 'En feil oppstod!',
            isError: true
        });
    };
    
    const checkEmpty = () => {
        if ($el.find('[data-favourites-item]').length <= 1) {
            Dispatch.emit(HIDE_SIDE_PANEL);
        }
    };
    
    const removeItem = $item => {
        gsap.to($item, { 
            height: 0, 
            duration: 0.8, 
            ease: 'quart.inOut', 
            onComplete: () => {
                if ($item) {
                    $item.remove();
                }
            } 
        });
        checkEmpty();
    };
    
    const init = () => {
        $el.on('submit', '[data-delete-form]', e => {
            e.preventDefault();
            const $form = $(e.triggerTarget);
            
            submitDelete($form);
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
