import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

export default el => {
    const BP_SCROLL_EFFECT_ENABLED = 750;
    
    const $el = $(el);
    const $items = $el.find('[data-news-item]');
    
    let isActive = false;
    let blockStart = 0;
    let blockHeight = 0;
    
    const measureThings = () => {
        blockStart = $el.offset().top;
        blockHeight = $el.height();
    };
    
    const updateItems = () => {
        let closestDist = 999999;
        let $closestItem = null;
        
        $items.each(val => {
            const $item = $(val);
            const distance = Math.abs(($item.offset().top + $item.height()) - (Viewport.scrollTop + Viewport.height));
            if (distance < closestDist) {
                closestDist = distance;
                $closestItem = $item;
            }
        });
        
        if ($closestItem) {
            $items.css({ opacity: 0.3 });
            $closestItem.css({ opacity: 1 });
        }
    };
    
    const resetItems = () => {
        $items.css({ opacity: '' });
    };
    
    const onBreakpoint = e => {
        const wasActive = isActive;
        isActive = Viewport.width >= BP_SCROLL_EFFECT_ENABLED;
        
        if (wasActive && !isActive) {
            resetItems();
        }
        
        if (!wasActive && isActive) {
            updateItems();
        }
    }; 
    
    const onScroll = e => {
        if (isActive) {
            if (Viewport.scrollTop > blockStart - Viewport.height) {
                updateItems();
            }
        }
    }; 
    
    const onResize = e => {
        if (isActive) {
            measureThings();
            updateItems();
        }
    }; 
    
    const init = () => {
        isActive = Viewport.width >= BP_SCROLL_EFFECT_ENABLED;
        measureThings();
        
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
        Viewport.off('breakpoint', onBreakpoint);
    };

    return {
        init,
        destroy
    };

};
