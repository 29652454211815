import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import request from '@vaersaagod/tools/request';
import serialize from 'form-serialize';

import { SHOW_FLASH_MESSAGE, SHOW_SIDE_PANEL, CART_UPDATED } from './events';

const $body = $('body');

let isRequesting = false;
let addedQty = 1; // stored for tracking purposes

const track = productTrackingData => {
    if (window.dataLayer === undefined) {
        window.dataLayer = [];
    }

    if (productTrackingData.quantity !== undefined) {
        productTrackingData.quantity = addedQty;
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
            currencyCode: 'NOK',
            add: {
                products: [productTrackingData]
            }
        }
    });

    addedQty = 1;
};

const submit = $form => {
    if (!isRequesting) {
        const fields = serialize($form.get(0), { hash: false });
        const fieldsData = serialize($form.get(0), { hash: true });

        if (fieldsData.purchasables !== undefined) {
            addedQty = 0;
            fieldsData.purchasables.forEach(p => {
                addedQty += parseInt(p.qty, 10);
            });
        }
        
        const $buttonContent = $form.find('[data-button-content]');
        const $spinner = $form.find('[data-spinner]');
        const { productTrackingData } = $form.data('add-to-cart-form');

        isRequesting = true;

        $buttonContent.css({ opacity: 0 });
        $spinner.css({ display: 'block' });

        request
            .post(document.location.href)
            .accept('application/json')
            .send(fields)
            .then(res => {
                const { status, body } = res || {};

                if (status === 200 && body) {
                    if (body.success === true) {
                        addedToCartSuccess(body);
                        track(productTrackingData);
                        
                        $buttonContent.addClass('-was-success');
                        setTimeout(() => {
                            $buttonContent.removeClass('-was-success');
                        }, 1500);
                    } else {
                        showErrorMessages(body);
                    }
                } else {
                    showGenericError(null);
                }
            })
            .catch(error => {
                showGenericError(null);
            })
            .then(() => {
                isRequesting = false;
                $buttonContent.css({ opacity: '' });
                $spinner.css({ display: 'none' });
            });
    }
};

const addedToCartSuccess = result => {
    Dispatch.emit(SHOW_FLASH_MESSAGE, {
        message: result.message
    });

    Dispatch.emit(CART_UPDATED, {
        cart: result.cart
    });
};

const showErrorMessages = response => {
    let errors = [];

    if (response.error !== undefined && response.error !== '') {
        errors.push(response.error);
    }

    if (response.errors !== undefined) {
        Object.keys(response.errors).forEach(val => {
            const err = response.errors[val];
            errors = [...errors, ...err];
        });
    }

    Dispatch.emit(SHOW_FLASH_MESSAGE, {
        message: errors.join('<br>'),
        isError: true
    });
};

const showGenericError = errorMessage => {
    Dispatch.emit(SHOW_FLASH_MESSAGE, {
        message: errorMessage || 'En feil oppstod!',
        isError: true
    });
};

const init = () => {
    $body.on('submit', 'form[data-add-to-cart-form]', e => {
        e.preventDefault();
        submit($(e.triggerTarget));
    });
};

const destroy = () => {

};

// Public api for module
export default {
    init,
    destroy
};
