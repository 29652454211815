import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import request from '@vaersaagod/tools/request';
import serialize from 'form-serialize';

import { SHOW_FLASH_MESSAGE, SHOW_SIDE_PANEL, CART_UPDATED } from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $spinner = $el.find('[data-spinner]');
    const $buttonContent = $el.find('[data-button-content]');

    let isRequesting = false;
    let addedQty = 1; // stored for tracking purposes
    let { productTrackingData } = props;

    const track = () => {
        if (window.dataLayer === undefined) {
            window.dataLayer = [];
        }
        
        if (productTrackingData.quantity !== undefined) {
            productTrackingData.quantity = addedQty;
        }
        
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'NOK',
                add: {
                    products: [productTrackingData]
                }
            }
        });
        
        addedQty = 1;
    };

    const submit = () => {
        if (!isRequesting) {
            const fields = serialize($el.get(0), { hash: false });
            const fieldsData = serialize($el.get(0), { hash: true });
            
            if (fieldsData.purchasables !== undefined) {
                addedQty = 0;
                fieldsData.purchasables.forEach(p => {
                    addedQty += parseInt(p.qty, 10);
                });
            }
            
            isRequesting = true;

            $buttonContent.css({ opacity: 0 });
            $spinner.css({ display: 'block' });

            request
                .post(document.location.href)
                .accept('application/json')
                .send(fields)
                .then(res => {
                    const { status, body } = res || {};

                    if (status === 200 && body) {
                        if (body.success === true) {
                            addedToCartSuccess(body);
                            track();
                        } else {
                            showErrorMessages(body);
                        }
                    } else {
                        showGenericError(null);
                    }
                })
                .catch(error => {
                    showGenericError(null);
                })
                .then(() => {
                    isRequesting = false;
                    $buttonContent.css({ opacity: '' });
                    $spinner.css({ display: 'none' });
                });
        }
    };

    const addedToCartSuccess = result => {
        Dispatch.emit(SHOW_FLASH_MESSAGE, {
            message: result.message
        });

        Dispatch.emit(CART_UPDATED, {
            cart: result.cart
        });
    };

    const showErrorMessages = response => {
        let errors = [];

        if (response.error !== undefined && response.error !== '') {
            errors.push(response.error);
        }

        if (response.errors !== undefined) {
            Object.keys(response.errors).forEach(val => {
                const err = response.errors[val];
                errors = [...errors, ...err];
            });
        }

        Dispatch.emit(SHOW_FLASH_MESSAGE, {
            message: errors.join('<br>'),
            isError: true
        });
    };

    const showGenericError = errorMessage => {
        Dispatch.emit(SHOW_FLASH_MESSAGE, {
            message: errorMessage || 'En feil oppstod!',
            isError: true
        });
    };

    const init = () => {
        $el.on('submit', e => {
            e.preventDefault();

            submit();
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
