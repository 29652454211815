import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import superagent from '@vaersaagod/tools/request';
import Components from '@vaersaagod/tools/Components';

import gsap from 'gsap';

import { usingTouch } from '../lib/helpers';

export default (el, { targetSelector }) => {

    const $el = $(el);

    const $filters = $el.find('[data-filters]');

    let isFiltersWrapperOpen = false;
    let activeElement;

    const isSmall = () => $el.find('[data-toggle]').get(0).offsetParent !== null;

    let wasSmall = isSmall();
    let activeFilter = null;
    let isTabbingLocked = false;
    let ajaxRequest;

    const lockTabbing = (nodeToLimit, nodeToFocus) => {
        if (isTabbingLocked) {
            Viewport.releaseTabbing();
        }
        Viewport.lockTabbing(nodeToLimit, nodeToFocus);
        isTabbingLocked = true;
    };

    const releaseTabbing = nodeToFocus => {
        if (isTabbingLocked) {
            Viewport.releaseTabbing(nodeToFocus);
        } else if (nodeToFocus) {
            try {
                nodeToFocus.focus();
            } catch (error) {
                console.error(error);
            }
        }
        isTabbingLocked = false;
    };

    const updateHtml = html => {
        if (!targetSelector) {
            console.warn('No target selector');
            return;
        }
        const $html = $(html);
        // Update product listing
        const productsContainer = $(targetSelector).get(0);
        const newProductsContainer = $html.find(targetSelector).get(0);
        if (!productsContainer || !newProductsContainer) {
            console.warn('Products container not found');
            return;
        }
        const fromHeight = $(productsContainer).height();
        gsap.timeline()
            .set(productsContainer, { height: fromHeight, overflow: 'hidden' })
            .to(productsContainer, { opacity: 0, duration: 0.3 })
            .add(() => {
                Components.destroy(productsContainer);
                $(productsContainer).html($(newProductsContainer).html());
                Components.init(productsContainer);
            })
            .to(productsContainer, { opacity: 1, duration: 0.3 }, 'in')
            .to(productsContainer, { height: 'auto', duration: 0.3 }, 'in')
            .set(productsContainer, { clearProps: 'all' });
        // Update filter counts & active/disabled states
        $filters.find('[data-items]').each(group => {
            const param = $(group).data('items');
            $(group).find('[data-item]').each(item => {
                const value = $(item).data('item');
                const newItem = $html.find(`[data-items="${param}"] [data-item="${value}"]`).get(0);
                if (!newItem) {
                    return;
                }
                const $item = $(item);
                const isActive = $(newItem).hasClass('is-active');
                const newCount = parseInt($(newItem).find('[data-count]').eq(0).text(), 10);
                const isDisabled = !newCount && !isActive;
                if (isActive) {
                    $item.addClass('is-active');
                } else {
                    $item.removeClass('is-active');
                }
                if (isDisabled) {
                    $item.attr({ 'aria-disabled': 'true' });
                } else {
                    $item.get(0).removeAttribute('aria-disabled');
                }
                $item.find('[data-count]').text(newCount || 0);
            });
        });
        // Show/hide/update toggle counters
        $filters.find('[data-filtertoggle]').each(filterToggle => {
            const param = $(filterToggle).data('filtertoggle');
            const numActive = $(filterToggle).find('[data-numactive]').get(0);
            const newNumActive = $html.find(`[data-filtertoggle="${param}"] [data-numactive]`).get(0);
            if (numActive && newNumActive) {
                $(numActive).text($(newNumActive).text());
                numActive.hidden = newNumActive.hidden;
            }
        });
        // Update total active items counter
        const totalActiveCount = $el.find('[data-toggle] [data-numactive]').get(0);
        const newTotalActiveCount = $html.find('[data-toggle] [data-numactive]').get(0);
        if (totalActiveCount && newTotalActiveCount) {
            $(totalActiveCount).text($(newTotalActiveCount).text() || '0');
            totalActiveCount.hidden = newTotalActiveCount.hidden;
        }
        // Show/hide reset button
        const hasFilters = !!$html.find('[data-item].is-active').length;
        if (hasFilters) {
            $el.addClass('has-filters');
        } else {
            $el.removeClass('has-filters');
        }
    };

    const updateFilters = cb => {
        // Loop over all active filters and build the query string
        const queryParams = [];
        const $items = $el.find('[data-items]');
        $items.each(group => {
            let activeItems = $(group).find('[data-item].is-active').get().map(item => item.dataset.item);
            if (!activeItems.length) {
                return;
            }
            const param = group.dataset.items;
            activeItems = activeItems.filter((item, index, self) => self.indexOf(item) === index);
            queryParams.push(`${param}=${activeItems.join(',')}`);
        });
        const queryString = queryParams.join('&');
        const baseUrl = window.location.href.split('?')[0];
        const url = baseUrl + (queryString ? `?${queryString}` : '');
        window.history.replaceState(null, null, url);
        if (ajaxRequest) {
            ajaxRequest.abort();
        }
        ajaxRequest = superagent.get(url);
        ajaxRequest
            .then(({ status, text: html }) => {
                if (status !== 200 || !html) {
                    throw new Error();
                }
                updateHtml(html);
                if (cb) {
                    cb();
                }
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                $el.removeClass('is-loading');
                ajaxRequest = null;
            });
        $el.addClass('is-loading');
    };

    const closeFilters = (tween = true) => {
        const $activeToggle = $el.find('[data-filtertoggle][aria-expanded="true"]');
        $activeToggle.each(toggle => {
            toggle.setAttribute('aria-expanded', 'false');
            const panel = toggle.nextElementSibling;
            gsap.killTweensOf(panel);
            if (tween) {
                gsap.to(panel, {
                    opacity: 0,
                    duration: 0.3,
                    onComplete: () => {
                        gsap.set(panel, { clearProps: 'all' });
                        panel.hidden = true;
                    }
                });
            } else {
                panel.hidden = true;
            }
        });
        activeFilter = null;
        if (activeElement) {
            activeElement.focus();
        }
    };

    const openFilter = toggle => {
        const filterPanel = toggle.nextElementSibling;
        toggle.setAttribute('aria-expanded', 'true');
        filterPanel.hidden = false;
        activeFilter = filterPanel;
        const tl = gsap.timeline()
            .fromTo(filterPanel, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0);
        if (!isSmall()) {
            tl.fromTo(filterPanel, { y: -8 }, { y: 0, duration: 0.3 }, 0);
        }
        const item = $(filterPanel).find('[data-item]').get(0);
        if (item) {
            item.focus();
        }
    };

    const closeFiltersWrapper = (tween = true) => {
        if (!isFiltersWrapperOpen) {
            return;
        }
        isFiltersWrapperOpen = false;
        const inner = $filters.get(0).firstElementChild;
        const afterClose = () => {
            $filters.css({ display: '' });
            $el.find('[data-toggle]').attr({ 'aria-expanded': 'false' });
            gsap.set([inner].concat(inner.children), { clearProps: 'all' });
            closeFilters(false);
        };
        if (tween && isSmall()) {
            gsap.timeline({
                onComplete: afterClose
            })
                .to(inner, { xPercent: 100, duration: 0.4, ease: 'Quad.easeIn' }, 0)
                .set(inner, { clearProps: 'all' });
        } else {
            gsap.killTweensOf([inner].concat(inner.children));
            afterClose();
        }
        const focusTo = isSmall() ? $el.find('[data-toggle]').get(0) : activeElement;
        releaseTabbing(focusTo);
    };

    const openFiltersWrapper = (tween = true) => {
        if (isFiltersWrapperOpen) {
            return;
        }
        isFiltersWrapperOpen = true;
        $filters.css({ display: 'block' });
        $el.find('[data-toggle]').attr({ 'aria-expanded': 'true' });
        const inner = $filters.get(0).firstElementChild;
        if (tween && isSmall()) {
            gsap.timeline()
                .fromTo(inner, { xPercent: 100 }, { xPercent: 0, duration: 0.4, ease: 'Quad.easeOut' }, 0)
                .fromTo(inner.children, { opacity: 0 }, { opacity: 1, duration: 0.3, ease: 'Sine.easeIn' }, 0.1)
                .set([inner].concat(inner.children), { clearProps: 'all' })
                .add(() => {
                    lockTabbing($filters.get(0), $filters.find('button').get(0));
                });
        } else {
            gsap.killTweensOf([inner].concat(inner.children));
            gsap.set([inner].concat(inner.children), { clearProps: 'all' });
            if (isSmall()) {
                lockTabbing($filters.get(0), $filters.find('button').get(0));
            }
        }
    };

    const toggleFiltersWrapper = () => {
        if (isFiltersWrapperOpen) {
            closeFiltersWrapper();
        } else {
            openFiltersWrapper();
        }
    };

    const resetFilters = () => {
        const $activeFilters = $filters.find('[data-item].is-active');
        if ($activeFilters.length) {
            $activeFilters.removeClass('is-active');
            $el.addClass('is-resetting');
            updateFilters(() => {
                $el.removeClass('is-resetting');
            });
            return;
        }
        closeFiltersWrapper();
    };

    const onToggleClick = e => {
        activeElement = e.target;
        toggleFiltersWrapper();
    };

    const onFilterToggleClick = e => {
        const { currentTarget: target } = e;
        const wasExpanded = target.getAttribute('aria-expanded') === 'true';
        closeFilters();
        if (wasExpanded) {
            return;
        }
        activeElement = target;
        openFilter(target);
    };

    const onFilterToggleKeyDown = e => {
        const { target } = e;
        const key = e.key || e.keyCode || e.which || null;
        if (['ArrowDown', 40].indexOf(key) > -1) {
            const item = $(target.nextElementSibling).find('[data-item]').get(0);
            if (!item) {
                return;
            }
            e.preventDefault();
            openFilter(target);
            item.focus();
        }
        const $html = $('html');
        if (!$html.hasClass('outline')) {
            $html.removeClass('no-outline').addClass('outline');
        }
    };

    const onFilterItemKeyDown = e => {

        const { target } = e;
        const key = e.key || e.keyCode || e.which || null;
        const focusNext = ['ArrowDown', 40].indexOf(key) > -1 || ['ArrowRight', 39].indexOf(key) > -1;
        const focusPrev = ['ArrowUp', 38].indexOf(key) > -1 || ['ArrowLeft', 37].indexOf(key) > -1;

        if (!focusNext && !focusPrev) {
            return;
        }

        e.preventDefault();

        const $items = $(target).parent('[data-items]').find('[data-item]');
        const index = $items.index(target);
        let item;

        if (focusNext) {
            item = $items.get(index + 1) || $items.get(0);
        } else if (focusPrev) {
            item = $items.get(index - 1) || $items.get($items.length - 1);
        }

        item.focus();

        const $html = $('html');
        if (!$html.hasClass('outline')) {
            $html.removeClass('no-outline').addClass('outline');
        }

    };

    const onCloseFilterBtnClick = () => {
        closeFilters();
    };

    const onFilterItemClick = e => {
        const { currentTarget: item } = e;
        if ($(item).attr('aria-disabled') === 'true') {
            return;
        }
        // Toggle active
        const isActive = $(item).hasClass('is-active');
        if (isActive) {
            $(item).removeClass('is-active');
        } else {
            $(item).addClass('is-active');
        }
        updateFilters();
    };

    const onBodyKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            closeFilters();
            closeFiltersWrapper();
        }
    };

    const onBodyClick = e => {
        const { target, type } = e;
        if (isSmall() || (usingTouch() && (type === 'click' || type === 'focusin'))) {
            return;
        }
        const doClose = (target !== el && !el.contains(target)) || (activeFilter && target !== activeFilter && !activeFilter.contains(target) && activeFilter !== target.nextElementSibling);
        if (doClose) {
            activeElement = document.activeElement !== activeElement ? document.activeElement : activeElement;
            closeFilters();
            closeFiltersWrapper();
        }
    };

    const onBreakpoint = () => {
        const isSmallNow = isSmall();
        if (!isSmallNow) {
            closeFiltersWrapper(false);
        }
        if (isSmallNow !== wasSmall) {
            closeFilters();
        }
        wasSmall = isSmallNow;
    };

    const onScroll = () => {
        if (!activeFilter || isSmall()) {
            return;
        }
        const { top, height } = activeFilter.getBoundingClientRect();
        if (height + top < 0) {
            closeFilters(false);
        }
    };

    const init = () => {
        $el.find('[data-toggle]').on('click', onToggleClick);
        $el.find('[data-closebtn]').on('click', closeFiltersWrapper);
        $el.find('[data-resetbtn]').on('click', resetFilters);
        $el.find('[data-filtertoggle]')
            .on('click', onFilterToggleClick)
            .on('keydown', onFilterToggleKeyDown);
        $el.find('[data-closefilterbtn]').on('click', onCloseFilterBtnClick);
        $el.find('[data-item]')
            .on('click', onFilterItemClick)
            .on('keydown', onFilterItemKeyDown);
        $('body')
            .on('keyup', onBodyKeyUp)
            .on('click touchend focusin', onBodyClick);
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        $el.find('[data-toggle],[data-closebtn],[data-resetbtn],[data-filtertoggle],[data-closefilterbtn],[data-item]').off('click touchend keydown keyup');
        $('body')
            .off('keyup', onBodyKeyUp)
            .off('click touchend focusin', onBodyClick);
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('scroll', onScroll);
        releaseTabbing();
    };

    return {
        init,
        destroy
    };

};
