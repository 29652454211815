import $ from '@vaersaagod/tools/Dom';
import superagent from '@vaersaagod/tools/request';
import Components from '@vaersaagod/tools/Components';
import serialize from 'form-serialize';
import i18n from '../../lib/i18n';
import Viewport from '@vaersaagod/tools/Viewport';

import gsap from 'gsap';

export default el => {

    const $el = $(el);

    let isSubmitting = false;
    let isConfirmed = false;

    const finish = receiptUrl => {
        isConfirmed = true;
        window.location.href = `/${receiptUrl}`;
    };

    const setFormErrorMessage = (form, message = '') => {
        const errorDiv = $(form).parent('[data-step]').find('[data-error]').get(0);
        if (!errorDiv) {
            return;
        }
        if (message) {
            errorDiv.hidden = false;
            errorDiv.textContent = message;
        } else {
            errorDiv.hidden = true;
            errorDiv.textContent = '';
        }
    };

    const setFormErrors = (form, errors = {}) => {
        // Hide all errors
        const $step = $(form).parent('[data-step]');
        $step.find('[data-errors]').each(node => {
            node.hidden = true;
            node.innerHTML = '';
        });
        const errorNames = Object.keys(errors);
        if (!errorNames.length) {
            return;
        }
        // Show the errors
        errorNames.forEach(name => {
            const errorDiv = $step.find(`[data-errors="${name}"]`).get(0);
            if (!errorDiv) {
                return;
            }
            errorDiv.innerHTML = [].concat(errors[name]).join('<br/>');
            errorDiv.hidden = false;
        });
        // Set focus to first error
        const firstError = $step.find(`[data-errors="${errorNames[0]}"]`).get(0);
        const firstErrorInput = $step.find(`[aria-describedby*="${firstError.id}"]`).get(0);
        if (firstErrorInput) {
            firstErrorInput.focus();
        }
    };

    const submitConfirmForm = (form, action = 0) => {

        if (isSubmitting || isConfirmed) return;

        isSubmitting = true;

        // Hide error div
        setFormErrorMessage(form);

        // Append action input for our current action
        const actions = ['contact-information', 'get-or-create-customer', 'place-booking', 'confirm-booking'];
        const actionName = actions[action] || null;
        if (!actionName) {
            throw new Error('Invalid action');
        }

        $(el).addClass('is-submitting');
        $(form).append(`<input type="hidden" name="action" value="prooptics/webbooking/booking/${actionName}" />`);

        // POST the request
        const data = serialize(form);

        superagent
            .post(window.location.href)
            .accept('application/json')
            .send(data)
            .then(({ status, body }) => {
                if (status !== 200 || !body.success) {
                    if (body.errors) {
                        // Validation errors
                        setFormErrors(form, body.errors);
                    } else if (body.message) {
                        setFormErrorMessage(form, body.message);
                    } else {
                        throw new Error();
                    }
                }
                return body;
            })
            .catch(error => {
                console.error({ error });
                setFormErrorMessage(form, i18n('error.generic'));
                return { success: false };
            })
            .then(({ success, redirect: receiptUrl }) => {
                isSubmitting = false;
                if (!success) {
                    // Something went wrong, so let's end it right here.
                    $(el).removeClass('is-submitting');
                    return;
                }
                if ((action + 1) < actions.length) {
                    // We're not done yet; do it again to hit the next action endpoint
                    submitConfirmForm(form, action + 1);
                    return;
                }
                finish(receiptUrl);
            });

    };

    const submitStepForm = form => {

        if (isSubmitting) {
            return;
        }

        isSubmitting = true;

        // Hide all errors
        setFormErrorMessage(form);
        setFormErrors(form);

        $(form).addClass('is-submitting');

        // POST the request
        const data = serialize(form);

        superagent
            .post(window.location.href)
            .accept('application/json')
            .send(data)
            .then(({ status, body }) => {
                if (status !== 200 || !body.success || !body.redirect) {
                    if (body.errors) {
                        // Validation errors
                        setFormErrors(form, body.errors);
                    } else if (body.message) {
                        setFormErrorMessage(body.message);
                    } else {
                        // Some other error.
                        throw new Error();
                    }
                    return null;
                }
                const redirect = `/${body.redirect}`;
                window.history.replaceState(null, null, redirect);
                return superagent.get(redirect);
            })
            .then(res => {
                if (!res) {
                    return;
                }
                const { status, text: html } = res;
                if (status !== 200) {
                    throw new Error();
                }
                const $newWebbooking = $(`<div>${html}</div>`).find('[data-component="webbooking/Webbooking"]').eq(0);
                Components.destroy(el.children);
                $el.html($newWebbooking.html());
                Components.init(el);
                // Focus to first button or input
                const { scrollTop } = Viewport;
                const firstButtonOrInput = $el.find('[data-active-step]').find('input:not([type="hidden"]):not(.sr-only),select,button').get(0);
                if (firstButtonOrInput) {
                    firstButtonOrInput.focus();
                }
                window.scrollTop = scrollTop;
                const offset = Math.round($el.find('[data-active-step]').eq(0).offset().top - 140);
                gsap.to($('html,body').get(), { scrollTop: offset, duration: 0.5, ease: 'Cubic.easeInOut' });
            })
            .catch(error => {
                console.error({ error });
                setFormErrorMessage(form, i18n('error.generic'));
            })
            .then(() => {
                isSubmitting = false;
                $(form).removeClass('is-submitting');
            });

    };

    const submitForm = form => {
        // Is it the confirmation form?
        const isConfirmForm = form.id === 'confirm';
        if (isConfirmForm) {
            return;
        }
        const $submitBtn = $(form).find('button[type="submit"]');
        if ($submitBtn.length && $submitBtn.attr('aria-disabled') === 'true') {
            // The submit button is disabled, so this form should not be submitted yet
            return;
        }
        submitStepForm(form);
    };

    const onFormSubmit = e => {
        e.preventDefault();
        const { target: form } = e;
        submitForm(form);
    };

    const onKroghPlusChange = e => {
        const { triggerTarget: target } = e;
        const consents = $(el).find('[data-kroghplus-consents]').get(0);
        if (!consents) {
            return;
        }
        consents.hidden = !target.checked;
    };

    const init = () => {
        // Submit the "Shop" step form on input change via AJAX
        $('select[name="values[shop]"]').on('change', e => {
            if (!e.target.value) {
                return;
            }
            submitForm(e.target.form);
        });
        // Also submit all forms via AJAX
        $(el).on('submit', onFormSubmit);
        // Make sure the confirm submit button works on browsers that don't support the `form` attribute for buttons
        $(el).on('click', 'button[type="submit"][form="confirm"]', e => {
            e.preventDefault();
            submitConfirmForm($el.find('form#confirm').get(0));
        });
        $(el).on('change', 'input#values-input-kroghplus', onKroghPlusChange);
        // Open/close app types accordions
        $(el).on('click', 'button[data-apptypeinfobtn]', e => {
            const { triggerTarget: toggle } = e;
            const isExpanded = $(toggle).attr('aria-expanded') === 'true';
            $(toggle).attr('aria-expanded', !isExpanded ? 'true' : 'false');
            toggle.nextElementSibling.hidden = isExpanded;
        });

    };

    const destroy = () => {
        $('select[name="values[shop]"]').off('change');
        $(el).off('click submit change');
    };

    return {
        init,
        destroy
    };

};
