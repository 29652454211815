import $ from '@vaersaagod/tools/Dom';
import { loadFlickity } from '../lib/async-bundles';

require('intersection-observer');

export default el => {

    const $el = $(el);

    let observer;

    let Flickity;
    let flkty;

    const initFlickity = () => {
        loadFlickity(flickity => {
            Flickity = flickity;
            flkty = new Flickity(el, {
                pageDots: false,
                adaptiveHeight: false,
                prevNextButtons: false,
                setGallerySize: true,
                groupCells: true,
                freeScroll: true,
                contain: true,
                cellAlign: 'left',
                resize: true,
                wrapAround: false,
                percentPosition: true,
                accessibility: true,
                dragThreshold: 10,
                lazyLoad: false,
                on: {
                    dragStart: () => {
                        $el.find('a,button').css({ pointerEvents: 'none' });
                    },
                    dragEnd: () => {
                        $el.find('a,button').css({ pointerEvents: '' });
                    }
                }
            });
        });
    };

    const init = () => {
        observer = new IntersectionObserver(entries => {
            const entry = entries[0];
            const { isIntersecting } = entry;
            if (isIntersecting) {
                observer.disconnect();
                observer = null;
                initFlickity();
            }
        }, {
            rootMargin: '100% 0% 100% 0%'
        });
        observer.observe(el);
    };

    const destroy = () => {
        if (observer) {
            observer.disconnect();
        }
        if (flkty) {
            flkty.destroy();
        }
    };

    return {
        init,
        destroy
    };

};
