import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Config from '@vaersaagod/tools/Config';
import gsap from 'gsap';

import { FAVOURITES_UPDATED } from '../lib/events';

export default el => {
    const $el = $(el);
    const $countContainerInner = $el.find('[data-count-inner]');

    let isVisible = false;

    const onFavouritesUpdated = (key, data) => {
        if (data.count !== undefined) {
            const qty = data.count;
            $countContainerInner.text(qty);
            
            console.log('her', qty, isVisible, data);
            
            if (qty > 0) {
                if (!isVisible) {
                    $el.removeClass('hidden').addClass('flex');
                    isVisible = true;
                }
            } else if (isVisible) {
                $el.addClass('hidden').removeClass('flex');
                isVisible = false;
            }
        }
    };

    const init = () => {
        const count = Config.get('favouritesCount');
        
        if (count > 0) {
            isVisible = true;
            $countContainerInner.text(count);
            $el.removeClass('hidden').addClass('flex');
        }
       
        Dispatch.on(FAVOURITES_UPDATED, onFavouritesUpdated);
    };

    const destroy = () => {
        Dispatch.off(FAVOURITES_UPDATED, onFavouritesUpdated);
    };

    return {
        init,
        destroy
    };
};
