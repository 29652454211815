import $ from '@vaersaagod/tools/Dom';

import gsap from 'gsap';

export default el => {

    const $el = $(el);

    const onNextBtnClick = () => {
        const $currentWeek = $el.find('[data-week]:not([hidden]');
        const currentWeekIndex = $currentWeek.data('week');
        const nextWeek = $el.find('[data-week]').get(currentWeekIndex + 1);
        if (!nextWeek) {
            return;
        }
        $currentWeek.get(0).hidden = true;
        nextWeek.hidden = false;
        const button = $(nextWeek).find('button[data-week-nextbtn').get(0) || $(nextWeek).find('button[data-week-prevbtn').get(0);
        button.focus();
    };

    const onPrevBtnClick = () => {
        const $currentWeek = $el.find('[data-week]:not([hidden]');
        const currentWeekIndex = $currentWeek.data('week');
        const prevWeek = $el.find('[data-week]').get(currentWeekIndex - 1);
        if (!prevWeek) {
            return;
        }
        $currentWeek.get(0).hidden = true;
        prevWeek.hidden = false;
        const button = $(prevWeek).find('button[data-week-prevbtn').get(0) || $(prevWeek).find('button[data-week-nextbtn').get(0);
        button.focus();
    };

    const init = () => {
        $el.on('click', 'button[data-week-nextbtn]', onNextBtnClick);
        $el.on('click', 'button[data-week-prevbtn]', onPrevBtnClick);
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
