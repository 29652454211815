import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

import { SHOW_SIDE_PANEL } from '../lib/events';

export default el => {
    const $el = $(el);

    const burger = $el.find('[data-burger]').get(0);
    const menu = burger.nextElementSibling;
    const $menu = $(menu);

    let menuOpen = false;
    let focusedElement = null;
    let breakpoint = Viewport.breakpoint.name;

    let isHidden = false;
    let hiddenThreshold;
    let currentScrollTop = Viewport.scrollTop;

    const isSmall = bp => ['min', 's', 'sp'].indexOf(bp || Viewport.breakpoint.name) > -1;

    const closeMenu = (tween = true) => {
        if (!menuOpen) return;
        
        Viewport.releaseTabbing(focusedElement);
        focusedElement = null;
        burger.setAttribute('aria-expanded', 'false');
        menuOpen = false;
        
        if (tween) {
            gsap.to($menu.nodes, { 
                left: '100%', 
                duration: 0.4, 
                ease: 'sine.in', 
                onComplete: () => {
                    menu.hidden = true;
                } 
            });
        } else {
            menu.hidden = true;
        }

        $el.removeClass('js-menu-open');
    };

    const openMenu = (tween = true) => {
        if (menuOpen) return;
        
        menuOpen = true;
        menu.hidden = false;
        burger.setAttribute('aria-expanded', 'true');
        focusedElement = document.activeElement || null;
        Viewport.lockTabbing(menu, $(menu).find('[data-closebtn]').get(0));
        
        if (tween) {
            gsap.set($menu.nodes, { left: '100%' });
            gsap.to($menu.nodes, { left: 0, duration: 0.8, ease: 'quint.out' });
        }

        $el.addClass('js-menu-open');
    };

    const toggleMenu = () => {
        if (menuOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    const hide = () => {
        if (isHidden) {
            return;
        }
        isHidden = true;
        $el.addClass('is-hidden');
    };

    const show = () => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        $el.removeClass('is-hidden');
    };

    const onBreakpoint = () => {
        const wasSmall = isSmall(breakpoint);
        if (wasSmall !== isSmall()) {
            closeMenu(false);
        }
        breakpoint = Viewport.breakpoint.name;
    };

    const onBodyKeyUp = e => {
        if (!menuOpen) return;
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            closeMenu();
        }
    };
    
    const onShortcutClick = e => {
        e.preventDefault();
        const $trigger = $(e.triggerTarget);
        Dispatch.emit(SHOW_SIDE_PANEL, { type: $trigger.data('shortcut-btn') });
    };

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (Math.abs(scrollTop - currentScrollTop) < 5) {
            return;
        }
        if (scrollTop < hiddenThreshold) {
            show();
        } else {
            const direction = scrollTop > currentScrollTop ? 'down' : 'up';
            if (direction === 'down') {
                hide();
            } else {
                show();
            }
        }
        currentScrollTop = scrollTop;
    };

    const onInnerFocus = e => {
        const { triggerTarget: target } = e;
        if ($(target).parent('[data-shortcuts-menu]').get(0)) {
            return;
        }
        show();
    };

    const onResize = () => {
        hiddenThreshold = isSmall() ? 80 : 110;
        if (!isHidden) {
            return;
        }
        requestAnimationFrame(() => {
            const { scrollTop } = Viewport;
            if (scrollTop <= hiddenThreshold) {
                show();
            }
        });
    };

    const init = () => {
        const navId = $(burger).attr('href');
        
        $(burger)
            .attr({
                tabIndex: '0',
                role: 'button',
                'aria-expanded': 'false'
            })
            .on('keydown', e => {
                const key = e.key || e.keyCode || e.which || null;
                if (['Enter', 13].indexOf(key) > -1) {
                    e.preventDefault();
                    toggleMenu();
                }
            })
            .on('click', e => {
                e.preventDefault();
                toggleMenu();
            })
            .get(0)
            .removeAttribute('href');
        
        $(menu).on('click', '[data-closebtn]', e => {
            e.preventDefault();
            closeMenu();
        });
        
        $('body').on('keyup', onBodyKeyUp);

        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
        
        $el.on('click', '[data-shortcut-btn]', onShortcutClick);

        $el.on('focusin', 'a,button', onInnerFocus);

        onResize();

        // Account for the menu being opened already before the JS had the chance to boot
        requestAnimationFrame(() => {
            if (navId && window.location.hash === navId) {
                openMenu(false);
                window.location.hash = '';
                if (window.history && window.history.replaceState) {
                    window.history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
                }
            }
        });
    };

    const destroy = () => {
        $(burger).off('click keydown');
        $(menu).off('click');
        $('body').off('keyup', onBodyKeyUp);
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
        $el.off('click focusin');
    };

    return {
        init,
        destroy
    };

};
