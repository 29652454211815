/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const VIEWPORTHEIGHT_CHANGE = 'VIEWPORTHEIGHT_CHANGE';
export const SHOW_FLASH_MESSAGE = 'SHOW_FLASH_MESSAGE';
export const HIDE_FLASH_MESSAGE = 'HIDE_FLASH_MESSAGE';
export const SHOW_SIDE_PANEL = 'SHOW_SIDE_PANEL';
export const HIDE_SIDE_PANEL = 'HIDE_SIDE_PANEL';
export const CART_UPDATED = 'CART_UPDATED';
export const FAVOURITES_UPDATED = 'FAVOURITES_UPDATED';
