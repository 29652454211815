import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

export default el => {
    const $el = $(el);

    const $findNearestBtn = $el.find('[data-nearestshopbtn]');
    const $findNearestBtnIcon = $findNearestBtn.find('[data-geo-icon]');
    const $findNearestBtnSpinner = $findNearestBtn.find('[data-spinner]');
    const $shops = $el.find('[data-shop-item]');
    const $listBox = $el.find('[data-shops-listbox-wrapper]');

    const scrollToTarget = $target => {
        const targetTop = $target.offset().top;
        gsap.to(window, { duration: 1.25, scrollTo: { y: targetTop - 150, autoKill: false }, ease: 'cubic.inOut' });
    };
    
    const getDistance = (lat1, lng1, lat2, lng2) => {
        const p = 0.017453292519943295; // Math.PI / 180
        const c = Math.cos;
        const a = 0.5 - c((lat2 - lat1) * p) / 2 + c(lat1 * p) * c(lat2 * p) * (1 - c((lng2 - lng1) * p)) / 2;
        return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    };

    const findNearestShop = () => {
        $findNearestBtnIcon.css({ opacity: 0 });
        $findNearestBtnSpinner.css({ display: 'block' });

        navigator.geolocation.getCurrentPosition(
            position => {
                const { latitude, longitude } = position.coords;

                let $closestShop = null;
                let currentDistance = null;

                $shops.each(shop => {
                    const $shop = $(shop);
                    const shopLat = $shop.data('lat');
                    const shopLng = $shop.data('lng');
                    const dist = getDistance(latitude, longitude, shopLat, shopLng);

                    if (currentDistance === null || (dist < currentDistance)) {
                        $closestShop = $shop;
                        currentDistance = dist;
                    }
                });

                if ($closestShop) {
                    scrollToTarget($closestShop);
                }

                $findNearestBtnIcon.css({ opacity: '' });
                $findNearestBtnSpinner.css({ display: 'none' });
            },
            () => {
                $findNearestBtnIcon.css({ opacity: '' });
                $findNearestBtnSpinner.css({ display: 'none' });
                console.log('Error getting position');
            }
        );
    };
    
    const handleListboxScroll = e => {
        const $trigger = $(e.triggerTarget);
        const url = $trigger.attr('href');
        const $target = $shops.filter(`[data-url="${url}"]`);
        
        if ($target.length) {
            e.preventDefault();
            scrollToTarget($target);
        }
    };

    const init = () => {
        if (!navigator.geolocation) {
            $findNearestBtn.css({ opacity: 0.5 });
            $findNearestBtn.on('click', e => {
                e.preventDefault();
            });
        } else {
            $findNearestBtn.on('click', findNearestShop);
        }
        
        $listBox.on('click', 'a', e => {
            handleListboxScroll(e);
        });
    };

    const destroy = () => {
        $findNearestBtn.off('click');
    };

    return {
        init,
        destroy
    };
};
