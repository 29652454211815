import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';

import { CART_UPDATED } from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $qtySelects = $el.find('[data-qty-select-wrap] select');
    const $totalPrice = $el.find('[data-total-price]');

    const { price } = props;
    
    const calculate = () => {
        let totalPacks = 0;
        
        $qtySelects.each(select => {
            totalPacks += parseInt(select.value, 10);
        });
        
        $totalPrice.text(new Intl.NumberFormat('no-NB', { style: 'currency', currency: 'NOK' }).format(totalPacks * price));
    };
    
    const onCartUpdated = () => {
        $qtySelects.val('1');
    };
    
    const init = () => {
        calculate();
        
        $qtySelects.on('change', () => {
            calculate();
        });
        
        Dispatch.on(CART_UPDATED, onCartUpdated);
    };

    const destroy = () => {
        $qtySelects.off('change');
        
        Dispatch.off(CART_UPDATED, onCartUpdated);
    };

    return {
        init,
        destroy
    };
};
