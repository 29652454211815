import $ from '@vaersaagod/tools/Dom';
import Components from '@vaersaagod/tools/Components';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import superagent from '@vaersaagod/tools/request';
import serialize from 'form-serialize';

import gsap from 'gsap';

import { SHOW_FLASH_MESSAGE } from '../lib/events';

export default el => {
    const $el = $(el);

    let isSubmitting = false;

    const injectNewMarkup = html => {
        const $html = $(`<div>${html}</div>`);
        const $newData = $html.find('[data-checkout-content]').eq(0);

        /*
        const $ajaxWrap = $html.find('[data-ajax-wrapper]');
        const opts = $ajaxWrap.data('ajax-wrapper');
        if (opts.flashMessage !== undefined && opts.flashMessage !== '') {
            Dispatch.emit(SHOW_FLASH_MESSAGE, { message: opts.flashMessage, isError: opts.isError });
        }
         */

        Components.destroy(el.children);
        $el.html($newData.html());
        Components.init(el);

        // Focus to first button or input
        const { scrollTop } = Viewport;
        const firstButtonOrInput = $el.find('[data-active-step]').find('input:not([type="hidden"]):not(.sr-only),select,button').get(0);
        if (firstButtonOrInput) {
            firstButtonOrInput.focus();
        }
        window.scrollTop = scrollTop;

        // Scroll to active step
        const $activeStep = $el.find('[data-active-step]');
        const stepName = $activeStep.data('step');
        let $scrollTarget = $activeStep;

        if (stepName === 'payment-method') {
            $scrollTarget = $el.find('[data-order-summary]');
        }

        const offset = Math.round($scrollTarget.eq(0).offset().top - 150);
        gsap.to(window, { duration: 0.5, scrollTo: { y: offset, autoKill: false }, ease: 'cubic.inOut' });

        maybeTrack();
    };

    const handle302 = res => {
        let redirect = res.headers['x-redirect'];
        redirect = redirect.replace(/^.*\/\/[^\/]+/, '').substr(1);
        loadStep(redirect);
    };

    const loadStep = stepUri => {
        if (stepUri === '') {
            return;
        }

        const next = `/${stepUri}`;
        window.history.replaceState(null, null, next);

        superagent
            .get(next)
            .ok(res => res.status < 400)
            .then(res => {
                if (!res) {
                    return;
                }
                const { status, text: html } = res;

                if (status === 200) {
                    injectNewMarkup(html);
                }

                if (status === 302) {
                    handle302(res);
                }

                if (status !== 200 && status !== 302) {
                    throw new Error();
                }
            })
            .catch(error => {
                console.error({ error });
                Dispatch.emit(SHOW_FLASH_MESSAGE, { message: 'En feil oppstod, vennligst prøv på nytt!', isError: true });
            })
            .then(() => {
                isSubmitting = false;
            });
    };

    const submitForm = form => {
        const $form = $(form);
        const $submitBtn = $form.find('button[type="submit"]');

        if (isSubmitting) {
            return;
        }

        isSubmitting = true;
        $form.addClass('is-submitting');

        const data = serialize(form, { hash: false, empty: true });

        // POST the request
        superagent
            .post(window.location.href)
            .send(data)
            .ok(res => res.status < 400)
            .then(res => {
                const { status, text } = res;
                // console.log(status, text, res);

                isSubmitting = false;
                $(form).removeClass('is-submitting');

                if (status === 200) { // this means the same page was returned, probably with error messages.
                    injectNewMarkup(text);
                }

                if (status === 302) {
                    handle302(res);
                }

                return null;
            })
            .catch(error => {
                console.error({ error });
                Dispatch.emit(SHOW_FLASH_MESSAGE, { message: 'En feil oppstod, vennligst prøv på nytt!', isError: true });
            });
    };

    const maybeTrack = () => {
        const $trackingNode = $el.find('[data-checkout-tracking]');

        if ($trackingNode.length > 0) {
            const data = $trackingNode.data('checkout-tracking');

            if (data !== undefined) {
                if (window.dataLayer === undefined) {
                    window.dataLayer = [];
                }

                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push(data);
            }
        }
    };


    const init = () => {
        $el.on('submit', 'form', e => {
            const $form = $(e.triggerTarget);

            if ($form.data('is-final') === undefined) {
                e.preventDefault();
                submitForm(e.triggerTarget);
            }
        });

        $el.on('click', '[data-jump-link]', e => {
            e.preventDefault();
            loadStep($(e.triggerTarget).data('jump-link'));
        });

        maybeTrack();
    };

    const destroy = () => {
        $el.off('submit');
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
