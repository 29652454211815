import $ from '@vaersaagod/tools/Dom';

export default (el, props) => {
    const $el = $(el);
    const $fieldsets = $el.find('.js-address-fieldset');
    const $billingSameAs = $el.find('#billingAddressSameAsShipping');
    
    const { shouldExpandBilling } = props;
    
    const init = () => {
        if ($fieldsets && $fieldsets.length) {
            $fieldsets.each(elem => {
                const $addressSelects = $(elem).find('.js-address-select');

                if ($addressSelects && $addressSelects.length) {
                    $addressSelects.each(elem => {
                        const $addressSelect = $(elem); 
                        const $radio = $addressSelect.find('.js-radio input');
                        const $body = $addressSelect.find('.js-body');

                        if ($radio && $radio.length) {
                            if ($body && $body.length) {
                                // Creating new address
                                $radio.on('change', ev => {
                                    if (ev.target.checked) {
                                        $body.removeClass('hidden');
                                    } else {
                                        $body.addClass('hidden');
                                    }
                                });
                                console.log($radio);
                                if ($radio.get(0).checked) {
                                    $body.removeClass('hidden');
                                }
                            } else {
                                // Selecting existing address
                                $radio.on('change', ev => {
                                    if (ev.target.checked) {
                                        const $newBox = $el.find(`.js-address-select[data-model-name="${ev.target.dataset.modelName}"]`);
                                        
                                        if ($newBox) {
                                            $newBox.find('.js-body').addClass('hidden');
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            });
        }
        
        if ($billingSameAs && $billingSameAs.length) {
            $billingSameAs.on('change', () => {
                const $billingFieldSet = $el.find('.js-address-fieldset.BillingAddress');
                $billingFieldSet.toggleClass('hidden');
            });

            if (shouldExpandBilling) {
                $billingSameAs.get(0).click();
            }
        }
    };

    const destroy = () => {
        if ($billingSameAs && $billingSameAs.length) {
            $billingSameAs.off('change');
        }
    };

    return {
        init,
        destroy
    };

};
