import $ from '@vaersaagod/tools/Dom';
import Components from '@vaersaagod/tools/Components';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import request from '@vaersaagod/tools/request';
import gsap from 'gsap';

import { SHOW_SIDE_PANEL, HIDE_SIDE_PANEL, HIDE_FLASH_MESSAGE } from '../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $inner = $el.find('[data-panel-inner]');
    const $spinner = $el.find('[data-panel-spinner]');
    const $content = $el.find('[data-panel-content]');
    const $announcer = $el.find('[data-panel-announcer]');

    const validTypes = ['cart', 'search', 'eye-test', 'profile', 'favourites'];
    const { baseUrl } = props;

    let isOpen = false;
    let thingThatWasFocusedBefore = null;
    let isTabbingLocked = false;
    
    const close = () => {
        if (!isOpen) {
            return;
        }

        isOpen = false;

        gsap.to($inner.nodes, {
            duration: 0.4,
            right: $inner.width() * -1,
            ease: 'sine.in',
            onComplete: () => {
                el.hidden = true;
                Components.destroy($content.get(0));
                $content.html('');
                $announcer.get(0).hidden = false;
                if (isTabbingLocked) {
                    isTabbingLocked = false;
                    try {
                        Viewport.releaseTabbing(thingThatWasFocusedBefore);
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        });

        gsap.to($el.nodes, {
            duration: 0.2,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            ease: 'sine.out'
        });

        gsap.to($content.nodes, {
            duration: 0.2,
            opacity: 0,
            ease: 'sine.out'
        });
    };

    const show = () => {
        if (isOpen) {
            return;
        }
        
        Dispatch.emit(HIDE_FLASH_MESSAGE);
        
        isOpen = true;

        try {
            thingThatWasFocusedBefore = document.activeElement || null;
            Viewport.lockTabbing(el, el);
            isTabbingLocked = true;
            console.info('tabbing is locked to side panel');
        } catch (error) {
            console.error(error);
        }

        el.hidden = false;

        gsap.to($inner.nodes, {
            duration: 0.8,
            right: 0,
            ease: 'quint.out',
            onComplete: () => {

            }
        });

        gsap.to($el.nodes, {
            duration: 0.3,
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            ease: 'sine.out'
        });
    };

    const parseData = data => {
        const $data = $(data);
        $content.empty().append($data.nodes);
        $spinner.get(0).hidden = true;
        $announcer.get(0).hidden = true;
        Components.init($content);
        
        gsap.to($content.nodes, { duration: 0.2, opacity: 1 });

        const firstThingToFocus = $content.find('button,a,input:not([type="hidden"])').get(0);
        if (firstThingToFocus) {
            try {
                firstThingToFocus.focus();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const loadContent = (type, wasOpen) => {
        gsap.to($content.nodes, {
            duration: wasOpen ? 0.1 : 0.01,
            opacity: 0,
            ease: 'sine.out',
            onComplete: () => {
                $content.empty();
                $spinner.get(0).hidden = false;
                gsap.delayedCall(wasOpen ? 0 : 0.9, () => {
                    request
                        .get(`${baseUrl}/${type}`)
                        .then(res => {
                            if (res.statusCode === 200) {
                                parseData(res.text);
                            } else {
                                // todo : feilmelding
                            }
                        });
                });
            }
        });
    };

    const reset = () => {
        $el.get(0).hidden = false;
        $inner.css({ right: $inner.width() * -1 });
        $el.get(0).hidden = true;
        $content.css({ opacity: 0 });
    };

    const onShowSidePanel = (key, data) => {
        const { type } = data;
        
        if (validTypes.indexOf(type) !== -1) {
            loadContent(type, isOpen);
            show();
        }
    };

    const onHideSidePanel = () => {
        if (isOpen) {
            close();
        }
    };

    const onBodyKeyUp = e => {
        if (!isOpen) {
            return;
        }

        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            close();
        }
    };

    const init = () => {
        reset();

        $el.on('click', '[data-sidepanel-close-btn]', () => {
            close();
        });
        
        $el.on('click', e => {
            if (e.target === e.triggerTarget) {
                close();
            }
        });

        $('body').on('keyup', onBodyKeyUp);
        Dispatch.on(SHOW_SIDE_PANEL, onShowSidePanel);
        Dispatch.on(HIDE_SIDE_PANEL, onHideSidePanel);
    };

    const destroy = () => {
        $el.off('click');
        $('body').off('keyup', onBodyKeyUp);
        Dispatch.off(SHOW_SIDE_PANEL, onShowSidePanel);
        Dispatch.off(HIDE_SIDE_PANEL, onHideSidePanel);
    };

    return {
        init,
        destroy
    };
};
